const config = {
    accentColors: [
        "red",
        "magenta",
        "purple",
        "blue",
        "cyan",
        "teal",
        "green",
    ],
};

export { config };
