import React, { useState } from "react";
import { ThemeContext } from './context';
import { config } from '../../core/config';

const applyTheme = (theme) => {
	try {
		// NextJS has issues with both localStorage and document uses.
		localStorage.setItem('j-theme', theme);
		document.querySelector("body").classList.remove("dark");
		document.querySelector("body").classList.remove("light");
		document.querySelector("body").classList.add(theme);
	} catch {}
};

const applyAccent = (accent) => {
	try {
		localStorage.setItem('j-dynamic-color', accent);
		document.querySelector('body').setAttribute('data-dynamic-color', accent);
	} catch {}
};

const initProvider = (props, theme, accent) => {
	if (props.themes) {
		applyTheme(theme);
	}
	if (props.accents) {
		applyAccent(accent);
	}
}

const checkThemeValue = theme => {
	if (theme !== "light" && theme !== "dark") {
		console.log(`"${theme}" is not allowed!`)
		return null;
	}

	return theme;
}

const checkAccentValue = accent => {
	if (!config.accentColors.indexOf(accent)) {
		return null;
	}
	return accent;
}

const ThemeProvider = props => {
	const get = (value, fallback) => {
		try {
			return localStorage.getItem(value);
		} catch {
			return fallback;
		}
	}

	const userTheme = get('j-theme', props.defaultTheme || "light");
	const userAccent = get('j-dynamic-color', props.defaultAccent || "blue");

	let defaults = {
		theme: checkThemeValue(userTheme),
		accent: checkAccentValue(userAccent)
	};

	if (!defaults.theme) defaults.theme = props.defaultTheme || "light";
	if (!defaults.accent) defaults.accent = props.defaultAccent || "blue";

    const [theme, setTheme] = useState(defaults.theme);
	const [accent, setAccent] = useState(defaults.accent);

    const value = {
        themes: {
			theme,
        	setTheme
		},
		accents: {
			accent,
			setAccent
		}
    };

	initProvider(props, theme, accent);

    return (
        <ThemeContext.Provider value={value}>
			{props.children}
		</ThemeContext.Provider>
    );
};

export { ThemeProvider };