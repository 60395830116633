import React from 'react';
import { Icon } from '../Icon';
import { getClass } from '../../../logic/dom';

// @props:
// props.className
// props.icon
// props.isLoading
// props.isLoadingText

const Wrapper = (props) => {
	return props.type === 'a' ? (
		<a {...props.props} className={getClass('j-button', props.props.className || "", props.props.surfaceDisabled || '' ? 'surface-disabled' : '', props.props.iconOnly ? 'icon-only' : '')}>
			{props.children}
		</a>
	) : (
		<button {...props.props} className={getClass('j-button', props.props.className || "", props.props.surfaceDisabled || '' ? 'surface-disabled' : '', props.props.iconOnly ? 'icon-only' : '')}>
			{props.children}
		</button>
	)
}

const Button = (props) => {
	return (
		<Wrapper
			props={props}
		>
			{props.isLoading && props.isLoadingText ? props.isLoadingText : props.children}

			{props.isLoading ? (
				<div className={props.iconOnly ? "" : "ml-1r"}><div className="j-loader border-color-gray-10 sm"></div></div>
			) : ('')}

			{props.icon && !props.isLoading ? (
				<Icon icon={props.icon} />
			) : ('')}
		</Wrapper>
	)
};

export { Button };